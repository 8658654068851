const LOGOS = {
  color: 'LOGO DIMENSIONE ARREDO.jpg',
  gray: 'LOGO DIMENSIONE ARREDO.jpg',
};

export default ({ width, height, version }) => {

  let fileName;

  switch (version) {
    case 'gray':
      fileName = LOGOS.gray;
      break
    default:
      fileName = LOGOS.color;
      break
  }

  return (
    <img
      className="logo"
      width={width}
      height={height}
      src={`./images/${fileName}`}
      alt="Dimensione arredo logo"
    />
  );
};
