import { Component, Fragment } from "inferno";
import Message from "../i18n/Message";
import { scrollTo } from "../util/navigation";
import ContactForm from "../components/ContactForm";

const getKey = (key) => `footer.${key}`;

class Footer extends Component {
  renderMenuItem(key) {
    return (
      <li>
        {/* eslint-disable-next-line */}
        <a className="navbar-item" onClick={() => scrollTo(key)}>
          <Message langKey={`header.${key}`} />
        </a>
      </li>
    );
  }

  renderAddresses() {
    return (
      <Fragment>
        <p>
          <b className="has-text-secondary">2 SHOWROOM:</b>
        </p>
        <br />
        <div>
          <div className="columns">
            <div className="column is-one-third">
              <a
                href="https://www.google.it/maps/place/Dimensione+Arredo+Srl/@38.7236635,16.0300094,17z/data=!3m1!4b1!4m5!3m4!1s0x13156597dfd306bf:0x7764d3c07ff9f82f!8m2!3d38.7236593!4d16.0321981?hl=it"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./images/MAPPA BRIATICO.jpg" alt="mappa briatico" />
              </a>
            </div>
            <div className="column is-two-thirds">
              <p>
                <b>Briatico</b>
              </p>
              <p>S. S. 522</p>
              <p>Briatico (VV)</p>
              <br />
              <p>Telefono: 0963 391386</p>
              <p>Email: dimensionearredo@tiscali.it</p>
            </div>
          </div>
        </div>

        <hr className="footer-separator" />

        <div>
          <div className="columns">
            <div className="column is-one-third">
              <a
                href="https://www.google.it/maps/place/Via+Mattia+Preti,+89851+Vena+VV/@38.6518411,16.0469941,15z/data=!4m5!3m4!1s0x13156ed1cfeeedaf:0x6c7041866e9a3621!8m2!3d38.6492113!4d16.0558062?hl=it"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./images/MAPPA JONADI.jpg" alt="mappa jonadi" />
              </a>
            </div>
            <div className="column is-two-thirds">
              <p>
                <b>Jonadi</b>
              </p>
              <p>Via Mattia Preti</p>
              <p>Jonadi (VV)</p>
              <br />
              <p>Telefono: 0963 544633</p>
              <p>Email: dimensionearredovibo@gmail.com</p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <section
        className="section has-background-grey-darker has-text-centered footer"
        id="contatti"
      >
        <div className="container">
          <h2 className="title is-4 is-uppercase is-bold has-text-white">
            <Message langKey={getKey("contattaci")} />
          </h2>
          <div className="columns footer-body">
            <div className="column is-half-tablet is-hidden-mobile has-text-left has-text-white addresses">
              {this.renderAddresses()}
            </div>
            <div className="column is-half-tablet">
              <ContactForm isSecondary={true} />
            </div>
            <div className="column is-half-tablet is-hidden-tablet has-text-centered has-text-white addresses">
              {this.renderAddresses()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
