import { Component } from "inferno";
import Message from "../i18n/Message";
import withEntranceAnimation from "../util/AnimateOnScreenEntrance";

const FIELDS = [
  {
    fieldKey: "madeInItaly",
    image: "/images/VANTAGGIO A.png",
    alt: "Made in italy",
  },
  {
    fieldKey: "sopralluogo",
    image: "/images/VANTAGGIO B.png",
    alt: "sopralluogo",
  },
  {
    fieldKey: "progettazione",
    image: "/images/VANTAGGIO C.png",
    alt: "progettazione",
  },
];
const FIELDS2 = [
  {
    fieldKey: "montaggio",
    image: "/images/VANTAGGIO D.png",
    alt: "Made in italy",
  },
  {
    fieldKey: "assistenza",
    image: "/images/VANTAGGIO E.png",
    alt: "sopralluogo",
  },
];

const ANIMATION_DURATION = 2200;

const getKey = (key) => `fields.${key}`;

const FieldsImages = ({ fields, offset }) => (
  <div className="field-container columns">
    <div className="column is-10 is-offset-1">
      <div className="columns is-mobile is-multiline">
        <div className="column is-12-tablet is-half-mobile">
          <div className="columns fields-images is-mobile is-multiline">
            {fields.map((field, index) => (
              <div
                className={
                  "column animated-field-column is-4-tablet is-12-mobile " +
                  (offset && index === 0 ? "is-offset-" + offset + "-tablet" : "")
                }
              >
                <img src={field.image} alt={field.alt} />
              </div>
            ))}
          </div>
        </div>
        <div className="column is-12-tablet is-half-mobile fields-description">
          <div className="columns is-mobile is-multiline title-container">
            {fields.map((f, index) => (
              <div
                className={
                  "column animated-field-column is-4-tablet is-12-mobile " +
                  (offset && index === 0 ? "is-offset-" + offset + "-tablet" : "")
                }
              >
                <h2 className="title is-4 is-size-4-mobile has-text-weight-normal has-text-black">
                  <Message langKey={getKey(f.fieldKey)} />
                </h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const AnimatedFieldImages = withEntranceAnimation(
  FieldsImages,
  ANIMATION_DURATION
);

class Fields extends Component {
  render() {
    return (
      <section
        id="percheSceglierci"
        className="section has-background-primary has-text-centered"
      >
        <div className="container section-title">
          <h2 className="title is-4 has-subtitle is-uppercase has-text-weight-black has-text-secondary">
            <Message langKey={getKey("title")} />
          </h2>
          <p className="has-text-black has-text-centered">
            <Message langKey={getKey("description")} />
          </p>
        </div>
        <div className="container">
          <div className="is-hidden-mobile">
            <AnimatedFieldImages fields={FIELDS} />
            <AnimatedFieldImages fields={FIELDS2} offset={2} />
          </div>
          <div className="is-mobile is-hidden-tablet">
            <AnimatedFieldImages fields={FIELDS.concat(FIELDS2)} />
          </div>
        </div>
      </section>
    );
  }
}

export default Fields;
