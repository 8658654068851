import Modal from "../elements/Modal";
import Message from "../i18n/Message";
import ContactForm from "./ContactForm";

const getKey = (key) => `footer.${key}`;

const ContactsModal = (props) => (
  <Modal
    onClose={props.onClose}
    fullscreen={false}
    title={<Message langKey={getKey("contattaci")} />}
    body={
      <div className='contacts-modal-container'>
        <ContactForm {...props} onComplete={() => props.onClose()} isSecondary={true} />
      </div>
    }
  />
);

export default ContactsModal;
