import { Component, createRef } from "inferno";
import { getWindowInteractionObserver } from "../util/interactionObserver";

const withEntranceAnimation = (Children, animationDuration = 2500, hideUntilStarts = false) => {
  class EntranceAnimation extends Component {
    constructor(props) {
      super(props);

      this.state = {
        renderComponent: !hideUntilStarts
      }
      this.element = createRef();
      this.callback = this.callback.bind(this);
    }

    animationStart() {
      this.element.current.classList.add("animated");
      if(hideUntilStarts){
        this.setState({renderComponent: true});
      }
    }

    animationEnd() {
      this.element.current.classList.remove("animated");
      this.element.current.classList.remove("waiting-animation");
    }

    callback(entry) {
      if (entry.intersectionRatio >= 0.5) {
        this.animationStart();
        this.timeout = setTimeout(() => {
          this.animationEnd();
        }, animationDuration);
        if (this.observer) {
          this.observer.unobserve(this.element.current);
        }
      }
    }

    componentDidMount() {
      this.observer = getWindowInteractionObserver(
        this.element.current,
        this.callback
      );
      if (!this.observer) {
        this.animationEnd();
      }
    }

    componentWillUnmount() {
      if (this.observer) {
        this.observer.unobserve(this.element.current);
      }
      clearTimeout(this.timeout);
    }

    render() {
      return (
        <div ref={this.element} className="waiting-animation">
          {this.state.renderComponent && <Children {...this.props} />}
        </div>
      );
    }
  }

  return EntranceAnimation;
};

export default withEntranceAnimation;
