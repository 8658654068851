import 'whatwg-fetch';

import { Component } from "inferno";
import NavContainer from "./nav/NavContainer";
import Hero from "./sections/Hero";
import Clients from "./sections/Clients";
import Services from "./sections/Services";
import Fields from "./sections/Fields";
import Showroom from "./sections/Showroom";
import AboutUs from "./sections/AboutUs";
import Footer from "./nav/Footer";
import Copyright from "./nav/Copyright";
import ContactsModal from "./components/ContactsModal";

class App extends Component {
  constructor() {
    super();
    this.state = {
      contactModalOpen: false,
      imageFound: null,
    };
  }

  componentDidMount() {
    const onScroll = () => {
      if(window.scrollY > 60) {
        document.body.classList.remove('on-top');
      } else
      {
        document.body.classList.add('on-top');
      }
    };
    document.addEventListener('scroll', onScroll);
    onScroll();
    this.testImage();
  }

  testImage() {
    var tester = new Image();
    tester.onload = () => this.setState({ imageFound: true });
    tester.onerror = () => this.setState({ imageFound: false });
    tester.src='https://zealous-shannon-a98985.netlify.app/images/test.jpg';
  }

  toggleContactsModal(contactModalOpen = !this.state.contactModalOpen) {
    this.setState({contactModalOpen});
  }

  render() {
    if (this.state.imageFound === null) {
      return  <div className="App"></div>
    }
    if (this.state.imageFound) {
      return  <div className="App">
        Sito in costruzione
      </div>
    }

    return (
      <div className="App">
        <NavContainer openContactsModal={this.toggleContactsModal.bind(this, true)} />
        <Hero openContactsModal={this.toggleContactsModal.bind(this, true)} />
        <Clients />
        <AboutUs />
        <Services />
        <Showroom />
        <Fields />
        <Footer />
        <Copyright />
        {this.state.contactModalOpen && <ContactsModal onClose={this.toggleContactsModal.bind(this, false)} />}
      </div>
    );
  }
}

export default App;
