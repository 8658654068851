import { Component } from "inferno";
import Message from "../i18n/Message";
import withEntranceAnimation from "../util/AnimateOnScreenEntrance";

const getKey = (key) => `showroom.${key}`;

const AnimatedYouTube = withEntranceAnimation((props) => (
  <iframe width="800" height="500" src="https://www.youtube.com/embed/LjdDBkMg1EY" title="YouTube video player"
          className="fade-up delay1 yt-video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
), 2500, true);

class Showroom extends Component {
  render() {
    return (
      <section
        id="showroom"
        className="section has-background-grey-light has-text-centered"
        style={{minHeight: '500px'}}
      >
        <div className="container section-title">
          <h2 className="title has-subtitle is-4 is-uppercase has-text-weight-black has-text-black">
            <Message langKey={getKey("title")} />
          </h2>
          <p className="has-text-centered" style={{color:'#525b69'}}>
            <Message langKey={getKey("description")} />
          </p>
          <div className="showroom-container columns is-mobile is-multiline">
            <div className="column is-12-mobile">
              <AnimatedYouTube />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Showroom;
