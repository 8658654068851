import { Component, createRef } from "inferno";
import { getWindowInteractionObserver25 } from "../util/interactionObserver";
import Slider from "react-slick";
import { scrollTo } from "../util/navigation";
// const getKey = (key) => `hero.${key}`;

function SampleNextArrow() {
  return null;
}

function SamplePrevArrow() {
  return null;
}
class Hero extends Component {
  constructor(props) {
    super(props);

    // Store reference somewhere
    this.element = createRef(); // Returns object {current: null}
  }

  componentDidMount() {
    this.observer = getWindowInteractionObserver25(
      this.element.current,
      (entry) => {
        if (entry.intersectionRatio < 0.25) {
          document.body.classList.add("over-hero");
        } else {
          document.body.classList.remove("over-hero");
        }
      }
    );

    if (!this.observer) {
      document.body.classList.add("over-hero");
    }

    this.timeout = setTimeout(
      () => this.element.current.classList.remove("animated"),
      1800
    );
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.unobserve(this.element.current);
    }
    clearTimeout(this.timeout);
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      fade: true,
      draggable: false,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      pauseOnDotsHover: true,
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    return (
      <section
        id="home"
        className="hero is-dark is-medium animated"
        ref={this.element}
      >
        <div className="hero-body">
          <Slider {...settings}>
            {["A", "B", "C", "D", "E", "F"].map((background) => (
              <div clastyle={{ position: "relative" }}>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("/images/BACK ${background}-min.jpg")`,
                    }}
                    className="hero-background"
                  >
                    <div className="container">
                      <div className="columns">
                        <div className="column is-5">
                          <div className="tile hero-message">
                            <h2 className="title is-3 has-text-weight-black">
                              Siamo presenti sul territorio da oltre 30 anni!
                            </h2>
                            <div>
                              <p className="is-6">
                                Ci distinguiamo per amore e passione nell'
                                arredare con gusto e qualit&agrave; ogni angolo
                                di casa.
                              </p>
                            </div>
                            <div>
                              <button
                                className="button is-fullwidth is-medium has-text-weight-bold is-uppercase is-light has-text-black"
                                style={{
                                  marginTop: "20px",
                                  borderRadius: "5px",
                                }}
                                onClick={() => scrollTo("chisiamo")}
                              >
                                Scopri di pi&ugrave;
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}

export default Hero;
