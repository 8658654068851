import { Component, Fragment } from "inferno";
import MobileMenu from "./MobileMenu";
import { Animated } from "inferno-animation";
import { scrollTo } from "../util/navigation";

class MobileMenuContainer extends Component {
  constructor() {
    super();
    this.navigateTo = this.navigateTo.bind(this);
  }

  navigateTo(menuName) {
    this.props.toggleMobileMenuOpened(false);
    setTimeout(() => scrollTo(menuName), 500);
  }

  render() {
    const { open } = this.props;

    return (
      open && (
        <Fragment>
          <Animated
            className="mobile-menu-container "
            prefix="menu-animation"
            onDidEnter={() =>
              document
                .getElementsByClassName("mobile-menu-container")[0]
                .classList.add("displayed")
            }
            onDidLeave={() =>
              document
                .getElementsByClassName("mobile-menu-container")[0]
                .classList.remove("displayed")
            }
          >
            <MobileMenu navigateTo={this.navigateTo} />
          </Animated>
          <div className="is-overlay is-hidden-desktop" />
        </Fragment>
      )
    );
  }
}

export default MobileMenuContainer;
