import { Component } from "inferno";

class Card extends Component {
  render() {
    return (
      <div className="card">
        <div className="card-image">
          <figure className="image">
            <img src={this.props.image} alt={this.props.altText} />
          </figure>
        </div>
        <div className="card-content">
          <h3 className="title is-3 is-size-3-mobile has-text-weight-normal has-text-black">
            {this.props.title}
          </h3>
          <p className="card-description is-size-6 is-size-6-mobile">
            {this.props.description}
          </p>
        </div>
      </div>
    );
  }
}

export default Card;
