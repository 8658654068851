import { Component } from "inferno";

class Copyright extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <section className="section has-background-grey-darker has-text-centered copyright">
        <p className="container is-size-7">&copy; {year} Dimensione Arredo srl - S.S. 522 Briatico (VV) - P.IVA 02824750794</p>
      </section>
    );
  }
}

export default Copyright;
