import { Component } from "inferno";

import languages from "./languages";

class Message extends Component {
  render() {
    const currentLanguage = "it";
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: languages[currentLanguage][this.props.langKey],
        }}
      />
    );
  }
}

export default Message;
