export const SERVICES = [
  "consulenza",
  "rilievoMisure",
  "progettazione",
  "trasportoEMontaggio",
  "finanziamenti",
  "assistenza",
];

export const getKey = (key) => `services.${key}`;
export const getServiceKey = (service, key) => getKey(`${service}.${key}`);
