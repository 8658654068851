const makeInteractionObserver = function (options) {
  let interactionObserver;
  const observables = [];

  const callback = (entries) => {
    entries.forEach((entry) => {
      observables.forEach((obv) => {
        if (obv.element === entry.target) {
          obv.elementCallback(entry);
        }
      });
    });
  };

  return (element, elementCallback) => {
    if (!!window.IntersectionObserver) {
      if (!interactionObserver) {
        interactionObserver = new IntersectionObserver(callback, options);
      }
      interactionObserver.observe(element);

      observables.push({
        element,
        elementCallback,
      });

      return interactionObserver;
    }
    return null;
  };
};

export const getWindowInteractionObserver = makeInteractionObserver({
  rootMargin: "0px",
  threshold: [0.5],
});

export const getWindowInteractionObserver25 = makeInteractionObserver({
  rootMargin: "0px",
  threshold: [0.25],
});
