import { Component } from "inferno";

import { animateOnAdd, animateOnRemove } from "inferno-animation";
import { findDOMNode } from "inferno-extras";

const enterCls = {
  start: "enter--start",
  active: "enter--active",
  end: "enter--end",
};

const leaveCls = {
  start: "leave--start",
  active: "leave--active",
  end: "leave--end",
};

class Modal extends Component {
  constructor() {
    super();
    this.onEscClicked = this.onEscClicked.bind(this);
  }

  onEscClicked(ev) {
    const keyCode = ev.key || ev.keyIdentifier || ev.keyCode || ev.which;
    if (keyCode === 27) {
      this.props.onClose();
    }
  }

  componentDidMount() {
    animateOnAdd(findDOMNode(this), enterCls, () => {
      document.addEventListener("keydown", this.onEscClicked);
      document.getElementsByTagName("html")[0].classList.add("has-modal-open");
      if (this.props.fullscreen) {
        document
          .getElementsByTagName("html")[0]
          .classList.add("has-fullscreen-modal-open");
      }
    });
  }
  componentWillUnmount() {
    document.getElementsByTagName("html")[0].classList.remove("has-modal-open");
    if (this.props.fullscreen) {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("has-fullscreen-modal-open");
    }
    animateOnRemove(findDOMNode(this), leaveCls, () => {
      document.removeEventListener("keydown", this.onEscClicked);
    });
  }

  render() {
    return (
      <div
        className={`dialog ${
          this.props.fullscreen ? "is-fullscreen" : "is-fullscreen-mobile"
        } ${this.props.additionalClassNames}`}

      >
        {!this.props.fullscreen && <div className="dialog__overlay"></div>}
        <div className="dialog__content">
          <div className="dialog__title">
            {this.props.title && (
              <h2 className="title is-5 is-uppercase is-bold has-text-dark">
                {this.props.title}
              </h2>
            )}
            {/* eslint-disable-next-line */}
            <a
              role="button"
              className="navbar-burger dialog--burger burger is-active"
              onClick={() => this.props.onClose()}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div className="dialog__body">{this.props.body}</div>
        </div>
      </div>
    );
  }
}

export default Modal;
