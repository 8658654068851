export default {
  header: {
    chisiamo: "Chi siamo",
    servizi: "Servizi",
    showroom: "Showroom",
    percheSceglierci: "Perché sceglierci",
    contatti: "Contatti",
    contattaci: "Contattaci",
    home: "Home",
  },
  hero: {
    qualita: "Qualità, non quantità",
    title:
      "Servizi verticali per la <b>Difesa</b><br /> della <b>tua</b> organizzazione<br /> su ogni fronte.",
    titleMobile:
      "Servizi verticali per la <b>Difesa</b> della <b>tua</b> organizzazione su ogni fronte.",
    subtitle:
      "Proteggi la tua organizzazione dalle ultime minacce e vulnerabilità<br /> con i nostri servizi di Sicurezza informatica avanzata.",
    scopriDiPiu: "Contattaci ORA",
  },
  services: {
    title: "I nostri servizi",
    consulenza: {
      title: "Consulenza",
      menuTitle: "Consulenza",
      shortDescription:
        "Sempre pronti ad offrire consulenze in ogni fase, dalla progettazione alla realizzazione, fino alla scelta dei colori e dei piccoli complementi d’arredo.",
      description: ``,
    },
    rilievoMisure: {
      title: "Rilievo misure",
      menuTitle: "Rilievo<br/>misure",
      shortDescription:
        "Ci occupiamo personalmente di ogni rilievo misure in tutto il territorio Regionale.",
      description: ``,
    },
    progettazione: {
      title: "Progettazione",
      menuTitle: "Progettazione",
      shortDescription:
        "I nostri architetti realizzano progetti completamente personalizzati, dalla distribuzione degli spazi, alla ricerca dello stile più adatto, tutto curato nei minimi dettagli, grazie alla progettazione in 3D.",
      description: ``,
    },
    trasportoEMontaggio: {
      title: "Trasporto e montaggio",
      menuTitle: "Trasporto<br/>e montaggio",
      shortDescription:
        "Altamente qualificata e in continuo aggiornamento la nostra squadra si occupa del trasporto e del montaggio con la massima serietà e attenzione. Ogni fase di consegna è monitorata dal team vendita.",
      description: ``,
    },
    finanziamenti: {
      title: "Finanziamenti su misura",
      menuTitle: "Finanziamenti<br/>su misura",
      shortDescription:
        "Proponiamo consulenze finanziarie per l’arredamento facendo scegliere ai nostri clienti le soluzioni di pagamento più idonee alle possibilità e alle loro esigenze.",
      description: ``,
    },
    assistenza: {
      title: "Assistenza",
      menuTitle: "Assistenza",
      shortDescription:
        "Garantiamo ai nostri clienti l’assistenza post vendita, rimanendo a disposizione per risolvere qualsiasi problema o inconveniente che si potesse verificare dopo l’acquisto. Molti ci preferiscono anche per l’affidabilità del nostro servizio.",
      description: ``,
    },
    buttonTitle: "Scopri di piú",
  },
  fields: {
    title: "Perché sceglierci",
    description:
      "Abbiamo cinque buoni motivi per cui sceglierci.",
    madeInItaly: "Prodotti  <br/>Made in Italy",
    sopralluogo: "Sopralluogo  <br/>gratuito",
    progettazione: "Progettazione <br/>d'interni 3D",
    montaggio: "Montaggio <br/>gratuito",
    assistenza: "Assistenza <br/>post-vendita",
  },
  showroom: {
    title: "Showroom",
    description: "Vieni a trovarci nei nostri showroom di Briatico (S.S. 522) e Jonadi (Via Mattia Preti)\n",
  },
  aboutUs: {
    title: "Chi siamo",
    line1:
      "Siamo presenti sul territorio da oltre 30 anni, distinguendoci per amore e passione nell’arredare con gusto e qualità ogni angolo di casa.",
    line2:
      "La nostra azienda  si affida da sempre ai migliori brand dell’ arredamento presenti sul mercato con un occhio sempre attento alle novità, alla ricerca e alla selezione di prodotti di elevata qualità.",
    line3:
      "Il nostro team di architetti e interior designer sono pronti ad accompagnarvi nella progettazione degli spazi domestici e nella scelta dell’arredamento, classico, moderno o contemporaneo, in linea con il gusto di voi clienti.",
    line4:
      "I nostri  spazi espositivi sono stati concepiti in modo nuovo e fortemente emozionale poiche’ Il nostro obiettivo è creare empatia coi nostri clienti, suggerendo  il modo migliore di vivere la loro casa.",
    contattaciOra: "Contattaci ORA",
  },
  footer: {
    contattaci: "Contattaci",
    sediOperative: "Sedi operative:",
  },
  form: {
    error: "Errore, riprova piú tardi",
    send: "INVIA",
  },
};
