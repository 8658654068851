import { Component, createRef } from "inferno";
const SCROLL_TIMEOUT = 4000;

class Clients extends Component {
  constructor(props) {
    super(props);

    this.handleTouchStart.bind(this);
    this.handleTouchEnd.bind(this);

    // Store reference somewhere
    this.container = createRef(); // Returns object {current: null}
    this.scrollable = createRef(); // Returns object {current: null}
    this.count = 0;

    this.state = {
      imageFound: false,
      initialClientX: null,
      logos: [
        {
          image: "/images/01 VENETA CUCINE-min.jpg",
          url: "https://www.venetacucine.com/",
        },
        {
          image: "/images/02 PRESOTTO-min.jpg",
          url: "https://www.presotto.com/",
        },
        {
          image: "/images/04 NOVAMOBILI-min.jpg",
          url: "https://www.novamobili.it/",
        },
        {
          image: "/images/04b CINQUANTA3-min.jpg",
          url: "https://www.cinquanta3.it/",
        },
        {
          image: "/images/05 MISURA EMME-min.jpg",
          url: "https://www.misuraemme.it/",
        },
        { image: "/images/06 FIMAR-min.jpg", url: "https://fimarmobili.com/" },
        { image: "/images/07 VOLTAN-min.jpg", url: "https://voltan.eu/" },
        {
          image: "/images/08 QUADRIFOGLIO-min.jpg",
          url: "https://www.quadrifoglio.com/",
        },
        {
          image: "/images/09 GIESSEGI-min.jpg",
          url: "https://www.giessegi.it/",
        },
        {
          image: "/images/10 TALENTI-min.jpg",
          url: "https://www.talentispa.com/",
        },
        {
          image: "/images/12 FERRETTI FERRETTI-min.jpg",
          url: "https://www.ferrettieferretti.it/",
        },
        { image: "/images/13 TWILS-min.jpg", url: "https://www.twils.it/" },
        {
          image: "/images/14 CALIA-min.jpg",
          url: "https://www.caliaitalia.com/",
        },
        {
          image: "/images/15 EPOQUE CO-min.jpg",
          url: "https://www.epoquebyegonfurstenberg.com/",
        },
        {
          image: "/images/16 FRANCO FERRI-min.jpg",
          url: "https://francoferri.it/",
        },
        {
          image: "/images/17 RIFLESSI-min.jpg",
          url: "https://www.riflessi.it/",
        },
        {
          image: "/images/18 REFLEX ANGELO new-min.jpg",
          url: "https://www.reflexangelo.com",
        },
        { image: "/images/19 FIAM-min.jpg", url: "https://www.fiamitalia.it/" },
        { image: "/images/20 MIDJ-min.jpg", url: "https://www.midj.com/" },
        {
          image: "/images/21 VERDE PROFILO-min.jpg",
          url: "https://verdeprofilo.com/",
        },
        {
          image: "/images/22 WALLDECO-min.jpg",
          url: "https://www.wallanddeco.com/",
        },
        {
          image: "/images/23 LONDONART-min.jpg",
          url: "https://www.londonart.it/",
        },
        {
          image: "/images/25 CARPET EDITION-min.jpg",
          url: "https://carpetedition.com/",
        },
      ],
    };
  }

  componentDidMount() {
    this.testImage();

    const translate = document.getElementsByClassName("clients-logo")[0]
      .clientWidth;

    const css = `.before-animate-left { 
    transform: translateX(-${translate}px);
    }`,
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    head.appendChild(style);

    style.type = "text/css";
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    this.interval = setInterval(() => {
      this.scroll("right");
    }, SCROLL_TIMEOUT);
  }

  scroll(direction = "right", fromInteraction = false) {
    if (fromInteraction) {
      clearInterval(this.interval);
    }
    if (this.timeout) {
      return;
    }
    const translate = document.getElementsByClassName("clients-logo")[0]
      .clientWidth;

    if (direction === "right") {
      this.scrollable.current.classList.add("animate");
      this.scrollable.current.style.transform = `translateX(-${translate}px)`;
    }

    if (direction === "left") {
      const lastLogo = this.state.logos[this.state.logos.length - 1];
      const restLogos = this.state.logos.slice(0, this.state.logos.length - 1);
      this.setState(
        {
          logos: [lastLogo, ...restLogos],
        },
        function () {
          this.scrollable.current.classList.add("before-animate-left");
          setTimeout(() => {
            this.scrollable.current.classList.add("animate");
            this.scrollable.current.style.transform = `translateX(0)`;
          }, 10);
        }
      );
    }

    this.timeout = setTimeout(() => {
      if (direction === "right") {
        const [firstLogo, ...restLogos] = this.state.logos;
        this.setState({
          logos: [...restLogos, firstLogo],
        });
      }

      this.scrollable.current.classList.remove("before-animate-left");
      this.scrollable.current.classList.remove("animate");
      this.scrollable.current.style.transform = null;
      this.timeout = null;
      if (fromInteraction) {
        this.interval = setInterval(() => {
          this.scroll("right");
        }, SCROLL_TIMEOUT);
      }
    }, 700);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleTouchStart(event) {
    this.setState({
      initialClientX: event.touches[0].clientX,
    });
  }

  handleTouchEnd(event) {
    const finalClientX = event.changedTouches[0].clientX;
    if (finalClientX - this.state.initialClientX > 70) {
      this.scroll("left", true);
    } else if (finalClientX - this.state.initialClientX < 70) {
      this.scroll("right", true);
    }

    this.setState({
      initialClientX: null,
    });
  }

  testImage() {
    var tester = new Image();
    tester.onload = () => this.setState({ imageFound: true });
    tester.src='/images/PROMOZIONE.jpg';
  }

  renderPromoSection() {
    if (!this.state.imageFound) {
      return null;
    }
    return (
      <div className="container has-text-centered">
        <hr className="promo-line" />
        <div>
          <h2 className="title is-4 has-subtitle is-uppercase has-text-weight-black has-text-secondary promo-title">
            PROMOZIONI
          </h2>
        </div>
        <img src="/images/PROMOZIONE.jpg" alt="Promozione" />
      </div>
    );
  }

  render() {
    return (
      <section className="section has-background-white">
        <div className="container">
          <div
            className="slider-container"
            ref={this.container}
            onTouchStart={(event) => this.handleTouchStart(event)}
            onTouchEnd={(event) => this.handleTouchEnd(event)}
          >
            <div className="slider-scrollable" ref={this.scrollable}>
              {this.state.logos.map(({ image, url }) => {
                let logoName = image.split("/").pop().split(".")[0];
                return (
                  <a href={url} target="_blank" rel="noreferrer">
                    <img
                      src={image}
                      className="clients-logo"
                      alt={logoName}
                      key={logoName}
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        {this.renderPromoSection()}
      </section>
    );
  }
}

export default Clients;
