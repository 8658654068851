import { Component } from "inferno";
import Message from "../i18n/Message";

const TIME_SHOW_SUCCESS = 1500;

const getKey = (key) => `form.${key}`;

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.sendRequest = this.sendRequest.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderSubmitButton = this.renderSubmitButton.bind(this);

    this.state = {
      name: "",
      email: "",
      message: "",
      loadingState: "none",
      isValid: false,
    };
  }

  handleChange(fieldName) {
    return (event) => {
      this.setState({ [fieldName]: event.target.value });
      this.validate();
    };
  }

  sendRequest(e) {
    e.preventDefault();
    const { name, email, message } = this.state;
    this.setState({ loadingState: "loading" });

    const formUrl = window.location.origin.indexOf("localhost") > -1
      ? "https://submit-form.com/7jnylzky"
      : "https://submit-form.com/7jnylzky";
    fetch(formUrl, {
      method: "POST",
      body: JSON.stringify({
        name,
        email,
        message,
      }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
    })
      .then(
        () => {
          this.setState({
            loadingState: "success",
            name: "",
            email: "",
            message: "",
          });
        },
        () => {
          this.setState({ loadingState: "error" });
        }
      )
      .finally(() => {
        setTimeout(() => {
          this.setState({ loadingState: "none" });
          this.props.onComplete && this.props.onComplete();
        }, TIME_SHOW_SUCCESS);
      });
    return false;
  }

  validate() {
    const { name, email, message } = this.state;
    const isValid =
      name.length > 0 &&
      message.length > 0 &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      );
    if (isValid !== this.state.isValid) {
      this.setState({ isValid });
    }
  }

  renderSubmitButton() {
    const { loadingState } = this.state;
    let buttonText = <Message langKey={getKey("send")} />;
    let buttonClasses;

    switch (loadingState) {
      case "loading": {
        buttonClasses = "is-loading";
        break;
      }
      case "success": {
        buttonClasses = "is-success";
        buttonText = (
          <span className="icon is-small">
            <i className="fas fa-check"></i>
          </span>
        );
        break;
      }
      case "error": {
        buttonClasses = "is-danger";

        buttonText = (
          <span>
            <span className="icon is-small">
              <i className="fas fa-times"></i>
            </span>

            <Message langKey={getKey("error")} />
          </span>
        );
        break;
      }
      default: {
        buttonClasses = "";
      }
    }

    buttonClasses +=
      " " + (!!this.props.isSecondary ? "is-dark has-text-white" : "is-primary has-text-dark");

    return (
      <button
        type="submit"
        className={`button is-fullwidth is-medium has-text-weight-bold ${buttonClasses}`}
        disabled={!this.state.isValid}
      >
        {buttonText}
      </button>
    );
  }

  render() {
    const secondary = !!this.props.isSecondary ? "secondary" : "primary";
    return (
      <form onSubmit={this.sendRequest}>
        <div className={`columns is-multiline contact-form ${secondary}`}>
          <div className="column is-half-tablet">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="input"
                  name="name"
                  placeholder="Nome"
                  value={this.state.name}
                  onInput={this.handleChange("name")}
                  required
                />
              </div>
            </div>
          </div>
          <div className="column is-half-tablet">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  onInput={this.handleChange("email")}
                  required
                />
              </div>
            </div>
          </div>
          <div className="column is-full">
            <div className="field">
              <div className="control">
                <textarea
                  className="textarea"
                  name="message"
                  placeholder="Messaggio"
                  rows="10"
                  value={this.state.message}
                  onInput={this.handleChange("message")}
                  required
                />
              </div>
            </div>
          </div>
          <div className="column is-full">{this.renderSubmitButton()}</div>
        </div>
      </form>
    );
  }
}

export default ContactForm;
