import { Fragment, Component } from "inferno";
import NavBar from "./NavBar";
import MobileMenuContainer from "./MobileMenuContainer";

class NavContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpened: false,
    };
    this.renderBurgerMenu.bind(this);
    this.toggleMobileMenuOpened = this.toggleMobileMenuOpened.bind(this);
  }

  toggleMobileMenuOpened() {
    this.setState({ menuOpened: !this.state.menuOpened });
    setTimeout(() =>
      document.getElementsByClassName("navbar-burger")[0].blur()
    );
  }

  isTouchDevice() {
    return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
  }

  renderBurgerMenu() {
    const { menuOpened } = this.state;
    return (
      // eslint-disable-next-line
      <a
        role="button"
        className={`navbar-burger burger ${menuOpened ? "is-active" : ""} ${!this.isTouchDevice() ? 'is-hoverable' : ''}`}
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        onClick={() => this.toggleMobileMenuOpened()}
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    );
  }

  render() {
    const { menuOpened } = this.state;

    return (
      <Fragment>
        <NavBar openContactsModal={this.props.openContactsModal} />
        {this.renderBurgerMenu(menuOpened)}
        <MobileMenuContainer
          open={menuOpened}
          toggleMobileMenuOpened={this.toggleMobileMenuOpened}
        />
      </Fragment>
    );
  }
}

export default NavContainer;
