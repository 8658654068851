import { Component } from "inferno";
import Message from "../i18n/Message";
import Slider from "react-slick";

const getKey = (key) => `aboutUs.${key}`;

const IMAGES = [
  "IMG-20210912-WA0008.jpg",
  "IMG-20210912-WA0099.jpg",
  "IMG-20210912-WA0091.jpg",
  "IMG-20210912-WA0066.jpg",
  "IMG-20210912-WA0074.jpg",
  "IMG-20210912-WA0081.jpg",
  "IMG-20210912-WA0083.jpg",
  "IMG-20210912-WA0084.jpg",
  "IMG-20210912-WA0088.jpg",
  "IMG-20210912-WA0065.jpg",
  "IMG-20210912-WA0058.jpg",
  "IMG-20210912-WA0052.jpg",
  "IMG-20210912-WA0043.jpg",
  "IMG-20210912-WA0042.jpg",
  "IMG-20210912-WA0037.jpg",
  "IMG-20210912-WA0035.jpg",
  "IMG-20210912-WA0030.jpg",
  "IMG-20210912-WA0027.jpg",
  "IMG-20210912-WA0022.jpg",
  "IMG-20210912-WA0009.jpg",
];

function SampleNextArrow() {
  return null;
}

function SamplePrevArrow() {
  return null;
}
const AboutUsSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    fade: true,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnDotsHover: true,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="container aboutUsSlider">
      <Slider {...settings}>
        {IMAGES.map((img, idx) => (
          <img
            key={idx}
            src={`./images/chi-siamo/${img}`}
            alt={`showroom ${idx}`}
          />
        ))}
      </Slider>
    </div>
  );
};

const AboutUsBody = () => (
  <div className="container has-text-light has-text-left">
    <div className="columns">
      <div className="column is-one-third-tablet is-hidden-mobile has-text-centered">
        <img
          className="hand-image"
          src="./images/FOTO SHOWROOM.jpg"
          alt="mano mobile"
        />
      </div>
      <div className="column is-two-third long-aboutus">
        <div>
          <p>
            <Message langKey={getKey("line1")} />
          </p>
        </div>
        <div>
          <p>
            <Message langKey={getKey("line2")} />
          </p>
        </div>
        <div className="column is-hidden-tablet has-text-centered">
          <img
            className="hand-image"
            src="./images/FOTO SHOWROOM.jpg"
            alt="SHOWROOM"
          />
        </div>
        <div>
          <p>
            <Message langKey={getKey("line3")} />
          </p>
        </div>
        <div>
          <p>
            <Message langKey={getKey("line4")} />
          </p>
        </div>
      </div>
    </div>
  </div>
);

class AboutUs extends Component {
  render() {
    return (
      <section
        id="chisiamo"
        className="section has-background-dark  has-text-centered aboutUs"
      >
        <div className="container section-title">
          <h2 className="title is-4 is-uppercase has-text-weight-black has-text-light">
            <Message langKey={getKey("title")} />
          </h2>
        </div>
        <AboutUsBody />
        <AboutUsSlider />
      </section>
    );
  }
}

export default AboutUs;
