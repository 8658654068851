import { Component } from "inferno";
import Message from "../i18n/Message";
import Card from "../elements/Card";
import withEntranceAnimation from "../util/AnimateOnScreenEntrance";
import { SERVICES, getKey, getServiceKey } from "../util/servicesUtil";

const AnimatedCard = withEntranceAnimation(Card);

class Services extends Component {
  constructor() {
    super();

    this.toggleModal = this.toggleModal.bind(this);
    this.changeTab = this.changeTab.bind(this);

    this.state = {
      modalOpened: false,
      service: SERVICES[0],
    };
  }

  toggleModal(open, service = this.state.service) {
    this.setState({
      modalOpened: open,
      service: service,
    });
  }

  changeTab(service = this.state.service) {
    this.setState({
      service,
    });
  }

  renderCard(service) {
    return (
      <div className="column is-narrow is-one-third-tablet is-full-mobile">
        <AnimatedCard
          title={<Message langKey={getServiceKey(service, "title")} />}
          description={
            <Message langKey={getServiceKey(service, "shortDescription")} />
          }
          buttonTitle={<Message langKey={getKey("buttonTitle")} />}
          altText={getServiceKey(service, "title")}
          image={`/images/${service}.png`}
        />
      </div>
    );
  }

  render() {
    return (
      <section id="servizi" className="section">
        <div className="container has-text-centered">
          <div className="section-title">
            <h2 className="title is-4 is-uppercase has-text-weight-black has-text-black">
              <Message langKey={getKey("title")} />
            </h2>
          </div>
          <div className="columns is-mobile is-multiline is-centered">
            {SERVICES.map(this.renderCard.bind(this))}
          </div>
        </div>
      </section>
    );
  }
}

export default Services;
