import { Component } from "inferno";
import Logo from "../logo";
import Message from "../i18n/Message";
import { MENU_ITEMS } from "./constants";
import { scrollTo } from "../util/navigation";

class NavBar extends Component {
  constructor() {
    super();
    this.renderMenuItem = this.renderMenuItem.bind(this);
    this.goToContactsForm = this.goToContactsForm.bind(this);
  }

  renderMenuItem(key) {
    return (
      // eslint-disable-next-line
      <a className="navbar-item" onClick={() => scrollTo(key)}>
        <Message langKey={`header.${key}`} />
      </a>
    );
  }

  goToContactsForm(){
    scrollTo('contatti');
  }

  render() {
    return (
      <nav className="navbar is-fixed-top">
        <div className="container">
          <div className="navbar-brand">
            {/* eslint-disable-next-line */}
            <a className="navbar-item" onClick={() => scrollTo('home')}>
              <Logo />
            </a>
          </div>
          <div className="navbar-menu">
            <div className="navbar-end">
              {MENU_ITEMS.map(
                this.renderMenuItem
              )}
              <a href="https://www.facebook.com/DimensioneArredosrl/" target="_blank" className="navbar-item facebook-icon">
                <img src="images/facebook.png" width={24} height={24} alt="Facebook icon" />
              </a>
              <a href="https://www.instagram.com/dimensionearredosrl/" target="_blank" className="navbar-item facebook-icon is-paddingless">
                <img src="images/instagram.png" width={24} height={24} alt="Instagram icon" />
              </a>
              <div className="navbar-item">
                <div className="buttons">
                  <button className="button is-dark has-text-white" onClick={this.goToContactsForm}>
                    <strong>
                      <Message langKey="header.contattaci" />
                    </strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
