import it from "./it";

const adaptLanguage = (langFile, baseKey = []) => {
  const adapted = Object.keys(langFile).reduce((acc, nextLangKey) => {
    if (typeof langFile[nextLangKey] === "object") {
      return {
        ...acc,
        ...adaptLanguage(langFile[nextLangKey], [...baseKey, nextLangKey]),
      };
    }
    acc[[...baseKey, nextLangKey].join(".")] = langFile[nextLangKey];
    return acc;
  }, {});

  return adapted;
};

export default {
  it: adaptLanguage(it),
};
