import { Component, createRef } from "inferno";
import { MENU_ITEMS } from "./constants";
import Message from "../i18n/Message";

class MobileMenu extends Component {
  constructor(props) {
    super(props);

    // Store reference somewhere
    this.element = createRef(); // Returns object {current: null}
    this.renderSingleMenuItem = this.renderSingleMenuItem.bind(this);
  }

  componentDidMount() {
    this.timeout = setTimeout(
      () => this.element.current.classList.remove("animated"),
      1500
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  renderSingleMenuItem(key) {
    return (
      <li>
        {/* eslint-disable-next-line */}
        <a onClick={() => this.props.navigateTo(key)}>
          <Message langKey={`header.${key}`} />
        </a>
      </li>
    );
  }

  render() {
    return (
      <aside className="menu menu-list-container animated" ref={this.element}>
        <ul className="menu-list">
          {this.renderSingleMenuItem("home")}
          {MENU_ITEMS.map(this.renderSingleMenuItem)}
        </ul>

        <div className="has-text-centered menu-footer" style={{marginTop:'20px'}}>
          <a href="https://www.facebook.com/DimensioneArredosrl/" target="_blank">
            <img src="images/facebook.png" width={32} height={32} alt="Facebook icon" />
          </a>
          <a href="https://www.instagram.com/dimensionearredosrl/" target="_blank">
            <img src="images/instagram.png" width={32} height={32} alt="Instagram icon" />
          </a>
        </div>
      </aside>
    );
  }
}

export default MobileMenu;
